import Container from 'components/Container'
import { Courses } from 'components/Courses/Courses'
import Layout from 'components/layouts/Layout'
import getSettings from 'lib/settings'
import { GetServerSideProps } from 'next'
import { getSession } from 'next-auth/client'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import React from 'react'

const HomePage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Head>
        <title>{t('Online Learning Platform')}</title>
      </Head>

      <Container>
        <div className='grid md:grid-cols-2 gap-16 mb-24'>
          <h1 className='font-heading font-semibold'>
            {t('Online courses and your main value proposition')}
          </h1>
          <p className='text-lg text-comet'>
            {t(
              'Esse aute tempor duis irure. Nulla do labore nostrud id cillum elit et ea do dolore dolore laboris quis irure. Ipsum exercitation ullamco amet enim voluptate. Dolore eu deserunt ut voluptate velit cillum. Incididunt culpa exercitation officia amet eiusmod in dolore officia ipsum.',
            )}
          </p>
        </div>
        <h2 className='font-heading font-semibold text-3xl mb-7'>
          {t('Explore')}
        </h2>
        <Courses />
      </Container>
    </>
  )
}

HomePage.hideSidebar = false

HomePage.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout>{page}</Layout>
}

export default HomePage

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      session: await getSession(context),
      ...(await serverSideTranslations(context.locale || '')),
      ...(await getSettings()),
    },
  }
}
